/*
LEADERBOARD STYLES
*/
.tournament__leaderboard {
    flex-grow: 1;
    overflow: scroll;
    min-width: 600px;

    padding-right: 40px;

    border-right: 3px solid white;
}

.leaderboard__title {
    color: white;

    text-align: center;
    font-weight: bold;
    font-size: 18px;
    padding: 0 0 20px 0;

    border-bottom: 1px solid white;
}

.leaderboard__table {
    color: white !important;
    text-align: center;

    font-size: 14px;
}
