.statistics__statistics {
    text-align: center;
}

.statistics__title {
    color: white;
    font-weight: bold;
    text-align: center;

    padding: 20px;
}

.statistics-table {
    color: white !important;
}
