@use "./colors" as *;
/*
INDEX STYLING
*/
.index__index {
    display: flex;
    flex-direction: row;

    padding: 30px 30px 10px 30px;
}

.index__column3 {
    margin-left: 60px;

    display: flex;
    flex-direction: column;
}

/*
SCORING KEY STYLING
*/
.scoring-key {
    // outline: 2px black solid;

    overflow: scroll;

    margin-left: 40px;
}

.scoring-key__title {
    padding-top: 10px;
    padding-bottom: 10px;

    border-bottom: 1px solid white;

    color: white;

    text-align: center;
    text-decoration: underline;
    font-weight: bold;
    font-size: 16px;
}

.scoring-key-table {
    font-size: 12px;

    color: white !important;

    margin-bottom: -2px !important;
}

.scoring-key-table__cell {
    text-align: center;
    padding: 4px !important;
}

/*
TOURNAMENT STATUS STYLES
*/
.tournament-status {
    width: 300px;
    padding-bottom: 32px;
}

.tournament-status-row {
    display: flex;
    justify-content: space-between;

    border-bottom: 1px dashed $tertiary;
    font-size: 14px;
}

.tournament-status-row__day {
    color: white;
    font-style: italic;
}

.tournament-status-row__status {
    font-weight: bold;
    color: $tertiary;
    // color: #f4dc04;
}

/*
POT STYLES
*/
.pot {
    font-size: 16px;

    text-align: center;
    color: white;

    padding-bottom: 8px;
}

.pot__places {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    text-decoration: underline;
}

.pot__distribution {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    font-weight: bold;
}
