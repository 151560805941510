.scores__scores {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    padding-top: 15px;
}
.score {
    background-color: white;

    margin: 10px;
    border: 1px solid black;

    width: 600px;
    min-height: 200px;
}

.score__player {
    position: relative;

    align-items: center;

    border-bottom: 2px solid black;

    padding-top: 12px;
    padding-bottom: 12px;
}

.player__name {
    justify-content: center;

    font-size: 14px;
    font-weight: bold;

    text-align: center;
}

.player__grand-total {
    position: absolute;
    top: 0;
    right: 0;

    padding: 10px;

    min-width: 100px;

    text-align: center;

    border-left: 2px solid black;
}

.grand-total__title {
    padding-top: 30px;
    flex: 0 1 40%;

    font-weight: bold;
    text-decoration: underline;
}

.grand-total__points {
    flex: 0 1 60%;

    font-size: 18px;
    font-weight: bold;
}

.score__score {
    display: flex;
    flex-direction: row;
}

.score__table {
    font-size: 12px;
}

.table__header {
    border: none !important;

    padding: 0;
}

.table__row--combined-total {
    font-weight: bold;
    border-top: 1px solid black !important;
    border-bottom: 1px solid black !important;

    align-items: center;
}

.table__row--field {
    align-items: center;
}

.table__cell {
    justify-content: center;
    align-items: center;

    padding: 2px !important;
    text-align: center;

    border-top: none !important;
}

.table__column-field {
    font-style: italic;
}
