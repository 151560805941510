@use "./colors" as *;

.container {
    padding: 0;
}

.last-updated {
    text-align: right;
    font-size: 12px;
    font-weight: bold;

    padding: 0 0 2px 0;
    margin: 0;

    color: $tertiary;
    background-color: $primary;
}

.main-view {
    max-height: 100vh;
    display: flex;
    flex-direction: column;
}

.welcome {
    padding-top: 4px;
    padding-bottom: 4px;

    background-color: $primary;
    color: $tertiary;
    font-size: 16px;
    font-weight: bold;

    text-align: center;
}

.masters-logo {
    height: 100px;

    color: $primary;
}

.tabs {
    background-color: $primary;

    .nav-tabs {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .nav-link {
        color: $tertiary;

        &:hover {
            color: white !important;
            font-weight: bold;
        }
        &:focus {
            color: white !important;
            font-weight: bold;
        }

        &.active {
            background-color: $secondary !important;
            color: white !important;
            font-weight: bold;
        }
    }
}

.tab {
    overflow: scroll;
}
