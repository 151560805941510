@use "./colors" as *;

.container-fluid {
    padding: 0 !important;
}

.signup {
    background-color: $primary;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.welcome-text {
    padding-top: 20px;
    height: 40px;
    font-weight: bold;
    color: $tertiary;
}

.fields {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: $tertiary;
}

.field {
    padding: 10px;
    display: flex;
    flex-direction: column;

    align-items: center;
}

.field__title {
    margin-bottom: 8px;
}

.field__select {
    width: 300px;
}
