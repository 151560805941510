.lineups {
    display: flex;
    overflow: scroll;

    padding-bottom: 20px;
}

.lineups__picks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    min-width: 200px;

    padding-bottom: 20px;
    // align-items: center;
}

.lineups__statistics {
    border-left: 1px solid white;
    overflow: scroll;

    min-width: 200px;
}

.pick {
    margin: 5px;

    width: 250px;

    text-align: center;
}

.pick__number {
    color: white;
    font-size: 12px;
}

.pick__name {
    color: white;
    font-style: italic;
    text-decoration: underline;
}

.pick__golfers {
    background-color: white;

    font-size: 14px;

    border-radius: 2%;
}

.pick__golfer {
    padding: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    display: block;
}

.pick__out {
    text-decoration: line-through;
}
