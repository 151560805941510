@use "./colors" as *;
.scoreboard__scoreboard {
    padding: 30px 50px 25px 50px;
    overflow: scroll;
}

.scoreboard__table {
    // background-color: white;
    position: relative;

    color: white !important;

    font-size: 15px;
    text-align: center;

    margin-bottom: 0 !important;
}

.table__row--header {
    border-top: 2px solid white !important;
    border-bottom: 2px solid black !important;
}

.table__cell--player {
    font-style: italic;
}

.table__cell--total-points {
    font-size: 16px;
    font-weight: bold;
}

.player-golfers {
    background-color: $primary;
    border-radius: 10px;
    border: 2px solid lightgray;

    padding: 5px;

    justify-content: center;

    text-align: center;

    color: white;
    font-size: 12px;

    display: flex !important;
    flex-direction: row;
    // flex: 1 1 0px;
}

.player-golfers-golfer {
    padding: 5px;
}

.player-golfers-golfer__name {
    padding: 5px;
    text-decoration: underline;
    font-weight: bold;
}

.player-golfers-golfer__total-points {
    padding: 5px;
    font-weight: bold;
}
