.golfers__golfers {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-top: 15px;
    padding-left: 0;
    padding-right: 0;
}

.golfer {
    background-color: white;

    position: relative;

    margin: 10px;
    border: 1px solid black;
}

.golfer__name {
    text-align: center;
    text-decoration: underline;
    font-weight: bold;

    padding-top: 10px;
    padding-bottom: 10px;
}

.total_rounds_points {
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    justify-content: center;

    width: 40px;
    height: 40px;

    padding: 10px;

    text-align: center;

    font-weight: bold;
    font-size: 14px;

    border-left: 1px solid black;
    border-bottom: 1px solid black;
}

.golfer__out {
    background-color: #ff7777;
}

.golfer__table {
    font-size: 12px;

    .table {
        margin-bottom: 0;
    }
}

.table__header {
    text-align: center;
    // font-size: 14px;
}

.table__row--total {
    font-weight: bold;
    border-top: 1px solid black !important;
    border-bottom: 1px solid black !important;

    align-items: center;
}

.table__cell {
    padding: 2px !important;
    text-align: center;
    // font-size: 14px;
}

.table__column--field {
    // font-size: 14px;
    font-style: italic;
}
